<template>
  <div class="confirm-consent-cont">
    <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage"></loading>
 
    <h2>请仔细阅读并同意以下告知内容</h2>
    <h4>请确认您的联系电话：{{phone}}<br></h4>
    <h5>
      我们能会向您致电以获取额外信息
    </h5>
    <!-- <textarea>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla aperiam sapiente dignissimos vitae, qui asperiores dolor laudantium ut quidem modi adipisci rerum dolores voluptatem id maiores veniam minus eos nesciunt provident perferendis reprehenderit blanditiis. Iure, ipsa. Delectus sit culpa accusantium aliquid quia debitis eius voluptas itaque unde sunt, non numquam minus nisi perspiciatis rerum ratione possimus placeat iste odit obcaecati blanditiis omnis mollitia exercitationem! Earum autem dolorum explicabo beatae animi odio porro illo velit fugit. Tenetur itaque laborum alias veritatis temporibus obcaecati culpa quidem nam, facere, consequuntur repellat, impedit perspiciatis! Perferendis saepe laudantium inventore, numquam repellendus reiciendis veniam quidem! Eaque hic perferendis deserunt autem magni non ipsam, fugit pariatur ducimus maxime nam enim nostrum minus in doloribus aperiam ipsa. Et eaque ipsam consequatur quisquam nemo ex optio nesciunt aut earum, dignissimos, delectus inventore dolore quo! Porro, enim dolorem inventore voluptate dolore eos omnis itaque soluta excepturi repellendus. Veritatis tempore recusandae at cupiditate inventore consequuntur, aut aperiam ut quidem numquam officiis accusamus dolore quisquam porro commodi voluptas dignissimos? Expedita cupiditate cumque omnis consequuntur quo porro ipsum blanditiis modi molestias in totam possimus tempora nihil ipsa, vel distinctio, ab inventore soluta, dolores nesciunt? Ipsum exercitationem voluptate ratione earum soluta esse aliquid culpa.
        </textarea> -->
    <iframe src="https://static.drwang.care/HIPPAnotice.pdf" width="200%" height="500px">
    </iframe><br>
    <a href="https://static.drwang.care/HIPPAnotice.pdf" download>如果无法打开，您可点击这里下载</a><br>
    <button class="yesb" @click="onAgreeButtonClick">
      我已阅读理解并同意以上内容<br>确认信息无误
      <br>进入线上候诊
    </button>
    <button class="nob" @click="onRejectButtonClick">
      信息需要更改或拒绝以上内容
      <br>返回修改
    </button>
  </div>
</template>

<script>
    // Import Loading overlay component see https://github.com/ankurk91/vue-loading-overlay
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

  import {
    Cache
  } from 'aws-amplify'
  import router from '@/router'
  import {
    components
  } from 'aws-amplify-vue';

  import axios from 'axios'

  export default {
    name: 'ConfirmConsentView',
    components: {
      Cache,
      components,
      Loading
    },
    data() {
      return {
        //Overlay Control
        isLoading: false,
        fullPage: true,
        //Paitient Data
        name: null,
        bdate: null,
        gender: null,
        phone: null,
        is_first_visit: null,
        insurance: null,
        home_address: null,
        scriptLoaded: false,
        insurance_name: null,
        insurance_id: null,
        symptoms: null
      }
    },
    methods: {
      check4cache: function(){
        var session_id = Cache.getItem("session_id");
        if(session_id != null){
          console.log("Session ID exist in cache, attempting to reload.")
          router.push({
              name: "WaitingRoom"
            })
        }
      },
      getInputFromCache: function () {
        this.name = Cache.getItem("name")
        console.log(this.name)
      },
      onAgreeButtonClick: function () {
        this.isLoading = true
        var keys = Cache.getAllKeys()
        var jsonPayload = {
          patient: {}
        }
        keys.forEach(elem => jsonPayload["patient"][elem] = Cache.getItem(elem))
        console.log(jsonPayload)
        axios.post(`https://api.drwang.care/prod/patient/putcheckinform`, {
            body: jsonPayload
          })
          .then(response => {
            let body_json_obj = JSON.parse(response.data.body)
            Cache.setItem('session_id', body_json_obj.session_id)
            router.push({
              name: "WaitingRoom"
            })
            console.log(Cache.getItem('session_id'))

          })
          .catch(e => {
            console.log(e)
            alert("发生错误，请检查您填写的信息并重试，您也可以直接致电诊所")
            this.isLoading = false
          })

      },

      onRejectButtonClick: function () {
        Cache.clear();
        router.push({
          name: "CheckIn"
        })
      },
    },
    mounted() {
      this.check4cache()
      var phone = Cache.getItem('phone')
      console.log(phone)
      this.phone = phone
      if (phone == null) {
        alert("请至少输入您的联系电话");
        Cache.clear();
        router.push({
          name: "CheckIn"
        })
      }
    }
  }
</script>

<style scoped>
  h2 {
    background: #1bb1dc;
    padding: 20px 30px 15px 45px;
    margin: -30px -30px 30px -30px;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    border: 1px solid #1bb1dc;
    font-family: sans-serif;
    grid-column: 1/3;
  }

  textarea {
    width: 100%;
    grid-column: 1/3;
    height: 320px;
    border: 1px solid #ccc;
    font: 16px/26px sans-serif;
    overflow: auto;

  }

  button {
    background-color: #1bb1dc;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 12px 8px;
    cursor: pointer;
  }

  .yesb {
    background-color: #2b8e4c;
  }

  .yesb:hover {
    background: #1587a7;
  }

  .nob {
    background-color: white;
    color: #f44336;
    border: 2px solid #f44336;
  }

  .nob:hover {
    background: #f44336;
    color: #FFF;
  }

  .confirm-consent-cont {
    padding: 30px;
    margin: 40px auto;
    font-family: sans-serif;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /* @media screen and (min-width: 600px) {
        .confirm-consent-cont {
            width: 600px;
        }} */
</style>